<template>
  <b-card>
    <div class="m-0 p-0 bg-white">
      <b-row class="">
        <b-col
          sm="12"
          class="d-flex justify-content-between mt-1"
        >
          <span class="h4"> {{ $t('subtotal') }}: </span>
          <span class="h4">$ {{ quote.subtotal | money }} </span>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col
          sm="12"
          class="d-flex justify-content-between"
        >
          <span class="h4 font-weight-bolder">{{ $t('shipping') }}: </span>
          <span
            class="h4 font-weight-bolder text-muted"
          >$ {{ quote.shipping_total | money }}
          </span>
        </b-col>

        <b-col
          sm="12"
          class="d-flex justify-content-between"
        >
          <span class="h4 font-weight-bolder">{{ $t('tax') }}: </span>
          <span
            class="h4 font-weight-bolder text-muted"
          >$
            {{
              (Number(quote.total) -
                (Number(quote.subtotal) + Number(quote.shipping_total)))
                | money
            }}
          </span>
        </b-col>

        <b-col
          sm="12"
          class="d-flex justify-content-between"
        >
          <span class="h4 font-weight-bolder">{{ $t('total') }}: </span>
          <span
            class="h4 font-weight-bolder text-success"
          >$ {{ quote.total | money }}
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-2 ">
        <b-col
          sm="12"
          class=""
        >
          <div class="d-flex flex-column">
            <b-button
              class="block"
              variant="primary mb-1"
              @click="buyout"
            >
              {{ $t('buy') }}
            </b-button>
            <b-button
              variant="primary"
              class="block mb-1"
              @click="bidding"
            >
              {{ $t('improvePrice') }}
            </b-button>
            <b-button
              block
              variant="outline-danger"
              @click="reject()"
            >
              {{ $t('reject') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          sm="12"
          class="text-justify"
        >
          <span class="font-weight-bolder text-small">{{
            $t('prequoteTotalInfo')
          }}</span>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {},

  data() {
    return {}
  },

  computed: {
    ...mapGetters('quotes', ['quote']),
  },

  methods: {
    ...mapActions('quotes', ['buyoutQuote', 'setQuoteBidding', 'rejectQuote']),

    buyout() {
      this.buyoutQuote(this.$route.params.quoteId).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('quotePublished'),
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
        this.$router.push({
          name: 'checkout',
          params: { quoteId: response.id },
        })
      })
    },

    bidding() {
      this.setQuoteBidding(this.$route.params.quoteId).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('biddingSet'),
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
        this.$router.push({
          name: 'quotes',
          params: { projectId: response.project_id },
        })
      })
    },
    reject() {
      this.rejectQuote({ quoteId: this.$route.params.quoteId }).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('quoteRejected'),
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.$router.push({
          name: 'quotes',
          params: { projectId: response.project_id },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.text-small {
  font-size: 12px;
}
</style>
