<template>
  <b-card>
    <div class="m-0 p-0 bg-white">
      <b-row class="mb-4">
        <b-col
          sm="12"
          class="text-center mt-2"
        >
          <span class="h2 text-primary font-weight-bolder mr-1">{{
            quote.customer_attributes.name
          }}</span>
          <span
            class="h2 text-success font-weight-bolder"
          >Este es el resumen de tu cotización</span>
        </b-col>
      </b-row>
      <b-row class="justify-content-center resume-card">
        <b-col
          v-for="(product, n) in quote.quote_items_attributes"
          :key="product.id"
          sm="12"
          class=""
          :class="n % 2 == 0 ? 'custom-bg' : ''"
        >
          <prequote-product
            class="my-1"
            :product="product"
          />
        </b-col>
      </b-row>
      <!-- <b-row class="mt-2 justify-content-end">
        <b-col
          sm="12"
          xl="6"
          class="text-right"
        >
          <span class="font-weight-bolder">{{ $t('prequoteTotalInfo') }}</span>
        </b-col>
        <b-col
          sm="12"
          xl="3"
          class="text-right"
        >
          <span class="h2">
            {{ $t('subtotal') }}: ${{ quote.subtotal | money }}
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-2 justify-content-end">
        <b-col
          sm="12"
          class="text-right"
        >
          <span class="h2 font-weight-bolder">{{ $t('shipping') }}: </span>
          <span
            class="h2 font-weight-bolder text-success"
          >$ {{ quote.shipping_total | money }}
          </span>
        </b-col>
        <b-col
          sm="12"
          class="text-right"
        >
          <span
            class="h2 font-weight-bolder"
          >{{ $t('totalListPrice') }}:
          </span>
          <span
            class="h2 font-weight-bolder text-success"
          >$ {{ quote.total | money }}
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-2 justify-content-end">
        <b-col
          sm="12"
          class="text-right"
        >
          <div>
            <b-button
              class="mr-1"
              variant="primary"
              @click="buyout"
            >
              {{ $t('buy') }}
            </b-button>
            <b-button
              variant="primary"
              @click="bidding"
            >
              {{ $t('improvePrice') }}
            </b-button>
          </div>
        </b-col>
      </b-row> -->
    </div>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PrequoteProduct from '@/components/pricing/PrequoteProduct.vue'

export default {
  components: {
    PrequoteProduct,
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('quotes', ['quote']),
  },

  methods: {
    ...mapActions('quotes', ['buyoutQuote', 'setQuoteBidding']),

    buyout() {
      this.buyoutQuote(this.$route.params.quoteId).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('quotePublished'),
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
        this.$router.push({
          name: 'checkout',
          params: { quoteId: response.id },
        })
      })
    },

    bidding() {
      this.setQuoteBidding(this.$route.params.quoteId).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('biddingSet'),
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
        this.$router.push({
          name: 'quotes',
          params: { projectId: response.project_id },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.resume-card {
  max-height: 480px;
  overflow-y: auto;
  scrollbar-width: thin;
}
.custom-bg {
  background-color: #f8f8f8 !important;
}
</style>
