<template>
  <div>
    <b-row>
      <b-col>
        <h2 class="font-weight-bold text-dark">
          {{ $t('prequote') }}
        </h2>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      opacity="0.9"
    >
      <b-row class="mt-2">
        <b-col>
          <pricing-table :items="[{ ...quote }]" />
        </b-col>
      </b-row>
    </b-overlay>
    <b-row
      v-if="!loading && quote.quote_status !== 'pending'"
      class="mt-2"
    >
      <b-col
        lg="9"
        sm="12"
      >
        <quote-resume :products="quote.quote_items_attributes" />
      </b-col>
      <b-col
        lg="3"
        sm="12"
      >
        <quote-resume-buttons />
      </b-col>
    </b-row>
    <b-row
      v-else
      class="mt-2"
    >
      <b-col sm="12">
        <b-card
          class="d-flex justify-content-center align-items-center flex-column"
        >
          <h2>Un momento, estamos preparando tu cotización</h2>
          <div class="text-center">
            <b-img
              width="200"
              :src="require('@/assets/images/yoconstruyo/aby3.gif')"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import PricingTable from '@/components/pricing/BasicTable.vue'
import QuoteResume from '@/components/pricing/QuoteResume.vue'
import QuoteResumeButtons from '@/components/pricing/QuoteResumeButtons.vue'

export default {
  components: {
    PricingTable,
    QuoteResume,
    QuoteResumeButtons,
  },

  data() {
    return {
      items: [
        {
          folio: '91716',
          address: 'Depotivo Cumbres',
          municipality: 'Monterrey, N.L.',
          postal_code: '67110',
          date: '06 Ago 2021',
        },
      ],
      loading: false,
    }
  },

  computed: {
    ...mapGetters('quotes', ['quote']),
  },
  created() {
    this.loading = true
    this.fetchQuote(this.$route.params.quoteId).then(response => {
      this.loading = false

      if (response.quote_status === 'pending') {
        this.reFetchQuote()
      }
    })
  },

  destroyed() {
    this.clearQuote({})
    this.stopTimer()
  },

  methods: {
    ...mapActions('quotes', ['fetchQuote']),
    ...mapMutations('quotes', {
      clearQuote: 'SET_QUOTE',
    }),

    reFetchQuote() {
      let tries = 0
      const timer = setInterval(() => {
        if (tries >= 5) this.stopTimer()
        this.fetchQuote(this.$route.params.quoteId)
          .then(response => {
            if (response.quote_status !== 'pending') {
              this.loading = false
              // eslint-disable-next-line
              stopTimer()
            } else {
              this.loading = true
            }
          })
          .finally(() => {
            tries += 1
          })
      }, 5000)

      const stopTimer = () => {
        clearInterval(timer)
      }
    },
  },
}
</script>

<style></style>
