<template>
  <div class="m-0 custom-container">
    <b-row class="h-100">
      <b-col
        sm="3"
        class="px-0"
      >
        <div class="h-100">
          <div class="d-flex align-items-center h-100">
            <div>
              <b-img
                style="max-height:70px"
                width="50px"
                class="rounded mx-1"
                :src="`${product.product_catalog.logo}`"
              />
            </div>
            <div>
              <h4>{{ product.product_catalog.name }}</h4>
              <div class="d-flex flex-column">
                <span class="text-muted h5">{{ product.serial_number }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        sm="3"
        class="min-h-100 d-flex justify-content-center align-items-center"
      >
        <div>
          {{ $t('quantity') }}:
          {{ product.quantity }}
        </div>
      </b-col>
      <b-col
        sm="3"
        class="min-h-100 d-flex justify-content-center align-items-center"
      >
        <div>
          {{
            $tc(
              unitsFilter(product.product_catalog.unit_type),
              product.quantity,
            )
          }}
        </div>
      </b-col>
      <b-col
        md="auto"
        sm="3"
        class="d-flex flex-row justify-content-center align-items-center px-3 custom-padding"
      >
        <div v-if="product.selected_supplier_branch_id === null">
          {{ $t('notAvaiable') }}
        </div>
        <div
          v-else
          class="d-flex flex-column"
        >
          <span class="font-weight-bolder">{{ $t('listPrice') }}</span>
          <span class="font-weight-bolder">
            <!-- S/IVA  -->
            $ {{ Number(product.unit_price) + Number(product.unit_commission_price) | money }}
          </span>
          <div>Total: $ {{ product.total | money }}</div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  computed: {
    ...mapGetters('app', ['unitsFilter']),
  },
  methods: {
    unitTypeFilter(value) {
      const unitTypeOptions = {
        meter: 'meter',
        square_meters: 'squareMeters',
        cubic_meters: 'cubicMeters',
        piece: 'piece',
        kilogram: 'kilogram',
        ton: 'ton',
        bundle: 'bundle',
        box: 'box',
        trip: 'trip',
      }
      return unitTypeOptions[value]
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-padding {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
